const grandalat_data = {
    "Header": {
      "title": "Custom Software Development",
      "paragraph": "Grandalat Solutions develops custom software, web apps, mobile apps, databases, UI/UX design, desktop, graphics & digital marketing as well as technical support"
    },
    "About": {
      "paragraph": "Grandalat Digital Agency Company, fueled by a dynamic team boasting over five years of collective experience, merges expertise from diverse backgrounds including advertising, software development, public relations, media, marketing, and corporate communications. Situated in Nigeria, we specialize in collaborating with niche product brands and professional service firms eager to expand their reach. Our mission is to assist clients in attracting fresh prospects, fostering meaningful customer engagement, and generating high-quality leads to drive sustainable growth. ",
      "Why": [
        "We establish good business relationship.",
        "Swift response to request.",
        "A Team dedicated and willing to emphasize hard-work and creativity in areas where they matter.",
      ],
      "Why2": [
        "We just the best and modern technologies/services best suited for your application.",
        "We take the responsibility and diligence and solving problems.",
        "We ignite creativity to your ideas. "
      ]
    },
    "Gallery": [
      {
        "title": "Mall Point Of Sales System",
        "largeImage": "img/portfolio/01-large.jpg",
        "smallImage": "img/portfolio/01-small.jpg"
      },/*
      {
        "title": "Traffic Offense System",
        "largeImage": "img/portfolio/02-large.jpg",
        "smallImage": "img/portfolio/02-small.jpg"
      },*/
      {
        "title": "Hospital service/activity management application",
        "largeImage": "img/portfolio/03-large.jpg",
        "smallImage": "img/portfolio/03-small.jpg"
      },
      
      {
        "title": "School Management System",
        "largeImage": "img/portfolio/schooled.png",
        "smallImage": "img/portfolio/schooled.png"
      },
      {
        "title": "Nigerian Police Force",
        "largeImage": "img/portfolio/policed.jpg",
        "smallImage": "img/portfolio/policed.jpg"
      },
      {
        "title": "E-Inventory System",
        "largeImage": "img/portfolio/evs.png",
        "smallImage": "img/portfolio/evs.png"
      },
      {
        "title": "Interior Design Website",
        "largeImage": "img/portfolio/interno.png",
        "smallImage": "img/portfolio/interno.png",
      },
      {
        "title": "Justshup.com",
        "largeImage": "img/portfolio/justshup.png",
        "smallImage": "img/portfolio/justshup.png",
      },
    ],
    "Services": [
      {
        "icon": "fa fa-mobile",
        "name": "Mobile App Development",
        "text": "We are experts with iOS, both iPhone and iPad, and Android, both tablet and phone. We also build a variety of hybrid-platform apps when both iOS and Android support are required"
      },
      {
        "icon": "fa fa-cart-arrow-down",
        "name": "Ecommerce site deployment",
        "text": "We love building layout-responsive ecommerce applications that work well on phones, tablets, and desktops. We also focus on building client-responsive "
      },
      {
        "icon": "fa fa-Desktop",
        "name": "Desktop Application",
        "text": "We have developed desktop application, both windows based desktop application and cross platform desktop applications with a variety of desktop client-type applications to fit custom business needs "
      },
      {
        "icon": "fa fa-heart",
        "name": "Graphics",
        "text": "We offer eye catching graphic products, be it logo, banners, ceremonial filers and more"
      },
      {
        "icon": "fa fa-bullhorn",
        "name": "Advertisement Creators",
        "text": "We create ads for your product and your business. Online Ads makers "
      },
      {
        "icon": "fa fa-pie-chart",
        "name": "Project Support And Maintenance",
        "text": "We support and maintain existing projects, and help update and add new features if requested."
      }
    ],
    "Testimonials": [
      {
        "img": "img/testimonials/avatar-m.svg",
        "text": "\"Love the professionalism\"",
        "name": "Micheal"
      },
      {
        "img": "img/testimonials/avatar-f.svg",
        "text": "\"Satisfied with the service rendered \"",
        "name": "Debbie"
      },
      {
        "img": "img/testimonials/lammy.jpg",
        "text": "\"They did their best and also made sure the work was done 100 percent \"",
        "name": "Olamide"
      },
      {
        "img": "img/testimonials/avatar-m.svg",
        "text": "\"They make sure the work is done, and also do their best to satisfy its customers \"",
        "name": "Anthony "
      },
      {
        "img": "img/testimonials/sharon.jpg",
        "text": "\"I'm satisfied with the service offered during my project. \"",
        "name": "Sharon"
      }
    ],
    "Team": [
      {
        "img": "img/team/preshie.jpg",
        "name": "Precious",
        "job": "Software Developer/ C.E.O"
      },
      {
        "img": "img/team/davo.jpg",
        "name": "David",
        "job": "Lead Software Developer/ C.T.O"
      }
    ],
    "Contact": {
      "address": "15, Ifeolu street, elewure sango ibadan, oyo state, nigeria. ",
      "phone": "+2349158578566",
      "email": "grandalat@gmail.com",
      "facebook": "fb.com",
      "twitter": "twitter.com",
      "youtube": "youtube.com"
    },
    "Features": [
      {
        "icon": "fa fa-comments-o",
        "title": "Customer Communication",
        "text": "Quality technical support & professional relationships"
      },
      {
        "icon": "fa fa-bullhorn",
        "title": "Astonishing Branding",
        "text": "We bring inspiration and excitement into your products and ideas"
      },
      {
        "icon": "fa fa-group",
        "title": "Team-work ",
        "text": "Team working together to provide the best possible result"
      },
      {
        "icon": "fa fa-magic",
        "title": "Creativity ",
        "text": "Creatively crafted ideas to unleash the best out of your products/ideas."
      }
    ]
  }

export default grandalat_data;