import { Image } from "./image";
import Fade from 'react-reveal/Fade';

export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Gallery</h2>
          <p>

          </p>
        </div>
        <div className='row'>
          <Fade left cascade>
            <div className='portfolio-items'>
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className={`col-sm-12 col-md-6 col-lg-6 ${d.long && 'long-img'}`}>
                  <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                </div>
              ))
              : 'Loading...'}
          </div>
          </Fade>
          
        </div>
      </div>
    </div>
  )
}
